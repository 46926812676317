import styles from "./styles.module.css"

function Footer() {
  return (
    <footer className={styles.footer_container}>
      <h1 className={styles.text}>Built By Luan Nguyen</h1>
    </footer>
  )
}

export default Footer
